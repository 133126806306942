import React, { useContext } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import { Link, graphql, navigate } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import Pagination from "material-ui-flat-pagination"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"

import LangContext from "../contexts/LangContext"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"

const useStyles = makeStyles(theme => ({
  date: {
    color: theme.palette.gray,
    paddingBottom: theme.spacing(1),
  },
  imageDiv: {
    display: "block",
    height: 200,
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}))

const ConditionsPage = props => {
  const {
    data: { conditionsPage, conditions },
    pageContext: { slug, locale, total, skip, limit, breadcrumbs },
  } = props

  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const items = conditions.edges.map(r => r.node)

  const schema = conditionsPage.data.schema_json.text || ""
  const ogp = conditionsPage.data.ogp_tags || ""
  const meta = {
    title: conditionsPage.data.meta_title,
    description: conditionsPage.data.meta_description,
    keywords: conditionsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {conditionsPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>
            {items.map(item => (
              <Grid item key={item.id} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <Link
                      to={`${localizedSlugPrefix(item)}/${slug}/${item.uid}/`}
                    >
                      {item.data.image.gatsbyImageData && (
                        <GatsbyImage
                          className={classes.imageDiv}
                          image={item.data.image.gatsbyImageData}
                          alt={item.data.title.text}
                        />
                      )}
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={9}>

                    <Typography
                      variant="h2"
                      component="h2"
                    >
                      <Link
                        to={`${localizedSlugPrefix(item)}/${slug}/${item.uid}/`}
                      >
                        {item.data.title.text}
                      </Link>
                    </Typography>

                    <Typography component="p">
                      {item.data.excerpt.text}
                    </Typography>
                    <br />
                    <Link
                      variant="body2"
                      color="textPrimary"
                      to={`${localizedSlugPrefix(item)}/${slug}/${item.uid}/`}
                    >
                      {i18n.translations.continueReading}
                    </Link>
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </Grid>
            ))}

            <Grid item>
              <Pagination
                limit={limit}
                offset={skip}
                total={total}
                onClick={(e, offset) => {
                  navigate(
                    `${localizedSlugPrefix(conditionsPage)}/${slug}/${offset < limit ? "" : offset / limit + 1
                    }`
                  )
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default ConditionsPage

export const query = graphql`
  query ConditionsPageQuery(
    $slug: String!
    $skip: Int!
    $limit: Int!
    $locale: String!
  ) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    conditionsPage: prismicConditionsPage(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      id
      data {
        title
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
      lang
    }

    conditions: allPrismicCondition(
      skip: $skip
      limit: $limit
      sort: { fields: data___title___text, order: ASC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          data {
            excerpt {
              text
            }
            title {
              text
            }
            image {
              gatsbyImageData(layout: CONSTRAINED, height: 200)
            }
            date
          }

          uid
          lang
        }
      }
    }
  }
`

ConditionsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    conditionsPage: PropTypes.object.isRequired,
    conditions: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
}
